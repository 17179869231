<template>
	<span :class="classes">{{round(value)}}</span>
</template>

<script>
import {valueDiff} from "@/ittijs/utils";

export default {
	props: {
		'old': {},
		'new': {},
		'old2': {}, // optional, for izravnitelna
		'new2': {}, // optional, for izravnitelna
		'classPos': {},
		'classNeg': {},
		'decimals': Number,
	},
	computed: {
		value(){
			const val = valueDiff(this.old, this.new, this.old2, this.new2);
			if (isNaN(val)) return '';
			return val;
		},
		classes(){
			return this.value < 0 ? this.classNeg : this.classPos;
		},
	},
	methods: {
		round(val){
			if (typeof val !== 'number') return null;
			if (typeof this.decimals === 'number' && !isNaN(this.decimals)) return val.toFixed(this.decimals);
			return val;
		},
	},
}
</script>