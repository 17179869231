<template>
	<div>
		<v-divider />
		<v-progress-linear indeterminate v-if="loading" />
		<v-divider v-if="loading" />
		<v-simple-table dense fixed-header :height="height">
			<thead>
			<tr>
				<th>Дата/Час</th>
				<th></th>
				<th v-if="fullAccess"></th>
				<th class="text-right">
					<v-btn small icon tabindex="-1" @click="insert">
						<v-icon color="green">mdi-plus-circle</v-icon>
					</v-btn>
				</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="item in items" :key="item.id">
				<td>
					<div><date-format :date="item.time" /></div>
					<div v-if="item.user">{{ item.user }}</div>
				</td>
				<td>
					<div style="white-space: pre-wrap;">{{ item.text }}</div>
				</td>
				<td><MF :imodel="model" :value="item.files" /></td>
				<td class="text-right" nowrap v-if="fullAccess">
					<v-btn small icon tabindex="-1" @click="edit(item)" color="primary">
						<v-icon>mdi-pencil-circle</v-icon>
					</v-btn>
					<v-btn small icon tabindex="-1" @click="remove(item)" color="red">
						<v-icon>mdi-delete-circle</v-icon>
					</v-btn>
				</td>
			</tr>
			</tbody>
		</v-simple-table>

		<v-dialog v-model="editRecord" max-width="600">
			<template v-slot:default="">
				<v-card>
					<edit
						max-content-height="calc(90vh - 120px)"
						v-if="editRecord"
						title="Бележка"
						:params="editRecordParams"
						@close="editRecord = false"
						@saveclose="editRecord = false; reloadList();"
						ref="EditForm"
						:imodel="model"
						:constrains="{imot_id:imot}"
						:edit-button-options="{showDelete:false}"
					>
						<template v-slot="{err,row}">
							<v-container>
								<edit-input name="text" outlined label="" :data="row" :errors="err" />
								<edit-input name="files" outlined :data="row" :errors="err" class="mt-2" />
							</v-container>
						</template>
					</edit>
				</v-card>
			</template>
		</v-dialog>

		<confirm-dialog ref="deleteConfirm" label-confirm="Изтрий" color-confirm="red darken-1" icon-confirm="mdi-delete">
			Моля потвърдете изтриването на записа
		</confirm-dialog>

	</div>
</template>

<script>
import { isoToDate, phpDate } from '@/ittijs/utils';
import Edit from '@/ittijs/Edit.vue';
import EditInput from '@/ittijs/Inputs/EditInput.vue';
import ConfirmDialog from '@/ittijs/ConfirmDialog.vue';
import ITTIModel from "@/ittijs/ITTIModel";
import {RolesEnum} from "@/ittijs/Authorization";
import MF from "@/ittijs/components/MF.vue";
import DateFormat from "@/ittijs/components/DateFormat.vue";

class NotesModel extends ITTIModel {
	constructor() {
		super('ImotNotes');
	}
	async getByImot(imot){
		return await this.fetch('getByImot', {imot});
	}
}

const model = new NotesModel;

export default {
	components: {DateFormat, MF, ConfirmDialog, Edit, EditInput},
	inject: ['authorization'],
	props: {
		imot: {},
		height: {
			default: 300,
		},
	},
	data(){
		return {
			editRecord: false,
			editRecordParams: {},
			model,
			loading: false,
			items: [],
		}
	},
	activated() {
		this.reloadList();
	},
	mounted() {
		this.reloadList();
	},
	watch: {
		imot: {
			immediate: true,
			handler(){
				this.reloadList();
			},
		},
	},
	computed: {
		fullAccess(){
			return this.authorization.hasRole(RolesEnum.OTCHETI_FULL)
		},
	},
	methods: {
		isoToDate,
		phpDate,
		async reloadList(){
			this.loading = true;
			this.items = await model.getByImot(this.imot);
			this.loading = false;
		},
		insert(){
			this.editRecordParams = {};
			this.editRecord = true;
		},
		edit(record){
			if (!this.fullAccess) return;
			this.editRecordParams = {id:record.id};
			this.editRecord = true;
		},
		remove(record){
			if (!this.fullAccess) return;
			this.$refs.deleteConfirm.open().then(res=>{
				if (res) {
					model.delete({id:record.id}).then(()=>{
						this.reloadList();
					});
				}
			})
		},
	},
}
</script>