<template>
	<my-dialog width="400" v-model="dialog" title="Kореспондентски номер" @open="init">
		<template #activator="{attrs,on}">
			<v-btn v-bind="attrs" v-on="on" icon :class="$attrs.activatorClass">
				<icon-tooltip icon="mdi-tag-plus-outline" top>
					Добави кореспондентски номер
				</icon-tooltip>
			</v-btn>
		</template>
		<v-container>
			<v-text-field v-model="refNum" label="Номер" outlined dense hide-details />
		</v-container>
		<template #actions>
			<v-btn small text @click="dialog = false">Откажи</v-btn>
			<v-spacer />
			<btn-loading-promise :click="submit" @ready="onReady" @error="throwMe">
				<template #default="{click,loading}">
					<v-btn small color="primary" @click="click" :loading="loading">Потвърди</v-btn>
				</template>
			</btn-loading-promise>
		</template>
	</my-dialog>
</template>
<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import IconTooltip from "@/ittijs/components/icon-tooltip.vue";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise.vue";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
import IModelMixin from "@/ittijs/IModelMixin";

export default {
	mixins: [ThrowMeMixin,IModelMixin],
	components: {BtnLoadingPromise, IconTooltip, MyDialog},
	props: {
		id: [String,Number],
	},
	data(){
		return {
			dialog: false,
			refNum: '',
		}
	},
	methods: {
		init(){
			this.refNum = '';
		},
		submit(){
			return this.imodel.addRefNumOther(this.id, this.refNum);
		},
		onReady(){
			this.dialog = false;
			this.$emit('ready');
		},
	},
}
</script>