<template>
	<my-dialog v-model="dialog" max-width="600">
		<v-form ref="form">
			<v-container>
				<v-row>
					<v-col>
						Статус:
						<v-chip label :color="statusInfo?.color" text-color="white" class="font-weight-bold">
							<v-icon left>{{ statusInfo?.icon }}</v-icon>
							{{ statusInfo?.label }}
						</v-chip>
						<data-loader :watch="{ticketId,status}" :callback="()=>imodel.getNextRefNum(ticketId, status)">
							<template #default="{data}">
								<ref-num-tag :ref-num="data?.ref_num_full" :type="data?.ref_num_type" class="ml-2" />
							</template>
						</data-loader>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-textarea v-model="data.notes" label="Бележки" outlined dense hide-details="auto" />
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field v-model="data.ref_num_other" label="Кореспонд. #" outlined dense hide-details="auto" />
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<managed-files v-model="data.files" label="Файлове" outlined dense hide-details="auto" warn-filesize-single="2MB" />
					</v-col>
				</v-row>
			</v-container>
		</v-form>
		<template #actions>
			<v-btn text @click="dialog = false">Затвори</v-btn>
			<v-spacer/>
			<btn-loading-promise :click="submit" @ready="onReady" @error="throwMe">
				<template #default="{click,loading}">
					<v-btn color="primary" @click="click" :loading="loading">Потвърди</v-btn>
				</template>
			</btn-loading-promise>
		</template>
	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import ManagedFiles from "@/ittijs/Inputs/ManagedFiles.vue";
import IModelMixin from "@/ittijs/IModelMixin";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise.vue";
import {TicketStatusMap} from "@/ittijs/Nomenclatures";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
import RefNumTag from "@/views/tickets/RefNumTag.vue";

export default {
	components: {RefNumTag, DataLoader, BtnLoadingPromise, ManagedFiles, MyDialog},
	mixins: [IModelMixin,ThrowMeMixin],
	props: {
		ticketId: [Number,String],
	},
	data(){
		return {
			dialog: false,
			status: null,
			data: {
				//
			},
		}
	},
	computed: {
		statusInfo() {
			return TicketStatusMap.get(this.status);
		}
	},
	methods: {
		open(status) {
			this.data = {};
			this.status = status;
			this.dialog = true;
		},
		submit(){
			return this.imodel.updateStatus(this.ticketId, this.status, this.data);
		},
		onReady(){
			this.dialog = false;
			this.$emit('ready');
		},
	},
}
</script>