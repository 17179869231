<template>
	<v-card>
		<v-card-title class="text-subtitle-1 headings py-2 pr-2 d-flex">
			Имоти ({{ items.length }})
		</v-card-title>
		<v-divider />
		<v-btn-toggle mandatory v-model="sort" dense tile borderless class="d-flex" color="primary">
			<v-btn small v-for="item in sortOptions" :key="item.value" :value="item.value" class="flex-grow-1">{{ item.text }}</v-btn>
			<v-icon>mdi-sort</v-icon>
		</v-btn-toggle>
		<v-divider />
		<v-list max-height="600" style="overflow: auto"
			ref="list"
			tabindex="0"
			@keydown.native.up.prevent.stop="gotoPrev"
			@keydown.native.down.prevent.stop="gotoNext"
		>
			<v-list-item-group
				v-model="internalValue"
				active-class="item-selected"
				:mandatory="mandatory"
			>
				<v-list-item
					tabindex="-1"
					v-for="imot in sortedItems"
					:key="imot.data.id"
					:ref="(el) => { if (!$refs.item) $refs.item = {}; $refs.item[ imot.data.imot_id ] = el; }"
					:value="imot.data.imot_id"
					:class="{
						'orange lighten-4': !$vuetify.theme.dark && imot.warning,
						'deep-orange darken-4': $vuetify.theme.dark && imot.warning,
					}"
					@click="$refs.list.$el.focus()"
				>
					<span>[{{ imot.data.imot_N.padStart(8, '&nbsp;') }}]</span>
					<span>{{ imot.data.location.padStart(8, '&nbsp;') }} - {{ imot.data.volume.padStart(4, '&nbsp;') }}м³ - {{ imot.data.client_name }}</span>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-card>
</template>

<style scoped>
.v-list-item {
	font-size: 90%;
	min-height: 22px;
	font-family: Consolas, monospace;
	padding: .1em 0;
	white-space: nowrap;
	overflow: hidden;
}
.v-list-item.item-selected {
	font-weight: bold;
}
</style>

<script>
import { debounce } from 'lodash';

export default {
	props: [
		'value',
		'items',
	],
	data(){
		return {
			internalValue: this.value,
			sort: 'loc',
			changeDebounced: debounce(this.onChange, 300),
			mandatory: !!this.value,
		}
	},
	watch: {
		value(val){
			this.internalValue = val;
			this.$nextTick(this.scrollSelectedIntoView);
		},
		internalValue(val){
			this.changeDebounced(val);
		},
	},
	computed: {
		selectSize(){
			return Math.max(2, Math.min(30, this.sortedItems.length));
		},
		sortOptions(){
			return [
				{
					value: "num",
					text: "номер",
				},
				{
					value: "loc",
					text: "локация",
				},
				{
					value: "vol",
					text: "обем",
				},
				{
					value: "nam",
					text: "име",
				},
			];
		},
		sortedItems(){
			const items = [...this.items];
			const strcmp = (s1,s2) => s1.localeCompare(s2, undefined, {numeric:true});
			return items.sort((e1,e2)=>{
				switch (this.sort) {
					case "num":
						return strcmp(e1.data.imot_N.toLowerCase(), e2.data.imot_N.toLowerCase());
					case "loc":
						return strcmp(e1.data.location.toLowerCase(), e2.data.location.toLowerCase());
					case "nam":
						return strcmp(e1.data.client_name.toLowerCase(), e2.data.client_name.toLowerCase());
					case "vol":
						return strcmp(e1.data.volume.toLowerCase(), e2.data.volume.toLowerCase());
				}
			});
		},
	},
	methods: {
		onChange(value){
			this.mandatory = !!value;
			this.$emit('input', value);
		},
		scrollSelectedIntoView(){
			if (this.$refs.item && this.$refs.item[ this.internalValue ]) {
				this.$refs.item[ this.internalValue ].$el.scrollIntoView({
					block: 'nearest'
				});
			}
		},
		gotoPrev(){
			let found = false;
			for (const imot of [...this.sortedItems].reverse()) {
				if (found) {
					this.internalValue = imot.data.imot_id;
					this.$nextTick(this.scrollSelectedIntoView);
					found = false;
					break;
				}
				if (imot.data.imot_id == this.internalValue) {
					found = true;
				}
			}
		},
		gotoNext(){
			let found = false;
			for (const imot of this.sortedItems) {
				if (found) {
					this.internalValue = imot.data.imot_id;
					this.$nextTick(this.scrollSelectedIntoView);
					found = false;
					break;
				}
				if (imot.data.imot_id == this.internalValue) {
					found = true;
				}
			}
		},
		advance(){
			// advance selection to next item; if no next item, unselect
			let found = false;
			for (const imot of this.sortedItems) {
				if (found) {
					this.mandatory = true;
					this.$emit('input', imot.data.imot_id);
					// scroll to new selected item
					// this.$nextTick(()=>{
					// 	this.$vuetify.goTo(
					// 		this.$refs.listGroup.selectedItem
					// 	);
					// });
					found = false;
					break;
				}
				if (imot.data.imot_id == this.value) {
					found = true;
				}
			}
			if (found) {
				this.mandatory = false;
				this.$emit('input', null);
			}
		},
	},
}
</script>