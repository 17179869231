<template>
	<v-timeline dense align-top>
		<v-timeline-item v-for="(item,index) in itemsFiltered" :key="index" light
			:color="TicketStatusMap.get(item.status)?.color"
			:icon="TicketStatusMap.get(item.status)?.icon"
		>
			<v-card color="grey lighten-1">
				<v-card-title class="py-1">
					<div>{{ TicketStatusMap.get(item.status)?.label }}</div>
					<ref-num-tag :ref-num="item.ref_num_full" :type="item.ref_num_type" class="ml-2" />
					<add-ref-num-other :id="item.id" v-if="showRefNumOther(item)" activator-class="ml-2" @ready="$emit('reload')" />
					<v-spacer/>
					<user-info :id="parseInt(item.user_id)" class="mr-2" />
					<date-time-format :datetime="item.time" />
				</v-card-title>
				<v-card-text class="grey lighten-2 pa-1" v-if="item.ref_num_other!==null || item.notes!='' || item.files!=''">
					<div v-if="item.ref_num_other!==null" class="ma-1">
						<strong>Кореспондентски номер:</strong>
						{{item.ref_num_other}}
					</div>
					<v-sheet class="text-pre-wrap ma-1 pa-2 grey lighten-4" rounded v-if="item.notes!=''">{{item.notes}}</v-sheet>
					<managed-files v-if="item.files!=''" readonly multiple :value="item.files" class="ma-1 grey lighten-4" />
				</v-card-text>
			</v-card>
		</v-timeline-item>
	</v-timeline>
</template>

<script>
import DateTimeFormat from "@/ittijs/components/DateTimeFormat.vue";
import UserInfo from "@/ittijs/components/UserInfo.vue";
import ManagedFiles from "@/ittijs/Inputs/ManagedFiles.vue";
import {TicketRefNumType, TicketStatusMap} from "@/ittijs/Nomenclatures";
import RefNumTag from "@/views/tickets/RefNumTag.vue";
import AddRefNumOther from "@/views/tickets/AddRefNumOther.vue";

export default {
	components: {AddRefNumOther, RefNumTag, ManagedFiles, UserInfo, DateTimeFormat},
	props: {
		items: Array,
	},
	computed  : {
		TicketStatusMap() {
			return TicketStatusMap
		},
		itemsFiltered(){
			// todo: calculate time differences
			return this.items;
		},
	},
	methods: {
		showRefNumOther(item){
			if (item.ref_num_other !== null) return false;
			return item.ref_num_type === TicketRefNumType.OUT;
		},
	},
}
</script>