/* eslint-disable @typescript-eslint/camelcase */

import ITTIModel from '../../ittijs/ITTIModel';

export default class extends ITTIModel {
	constructor() {
		super('Imot');
	}
	getSelectOptions(params){
		return this.fetch('getSelectOptions', params);
	}
	getLatestSessionInfo(imot){
		// return object with keys utility, session, station, imot
		// which can be used to make a link to the client screen
		return this.fetch('getLatestSessionInfo', {imot});
	}
	getLastSessionState(imot, station){
		return this.fetch('getLastSessionState', {imot, station});
	}
	lastSessionAdd(imot, station){
		return this.fetch('lastSessionAdd', {imot, station}, {});
	}
	lastSessionRemove(imot, station){
		return this.fetch('lastSessionRemove', {imot, station}, {});
	}
	getLastSessionDataId(imot, station){
		return this.fetch('getLastSessionDataId', {imot, station});
	}
	createVodaContract(
		imot,
		contract_date,
		client_name,
		address,
		client_phone,
		client_email,
		voda_abonat_N,
		device_list,
		signature_svg,
	) {
		return this.fetch('createVodaContract', {
			imot,
		}, {
			contract_date,
			client_name,
			address,
			client_phone,
			client_email,
			voda_abonat_N,
			device_list,
			signature_svg,
		})
	}
}

export class ImotFrontDesk extends ITTIModel {
	constructor() {
		super('ImotFrontDesk');
	}
}
