var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"right":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":"","color":"primary"}},[_vm._v("mdi-pencil")])],1)]}},{key:"default",fn:function(){return [_c('v-card',[(_vm.open)?_c('edit-column',{attrs:{"max-content-height":"calc(90vh - 120px)","title":"Абонатна станция","params":{id:_vm.station},"imodel":_vm.model,"edit-button-options":{showDelete:false},"fields":[
					'domoupravitel_name',
					'domoupravitel_phone',
					'domoupravitel_email',
					'authorized_persons',
					'notes',
				]},on:{"close":function($event){_vm.open = false},"saveclose":function($event){_vm.open = false; _vm.$emit('reload')}}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}})
}
var staticRenderFns = []

export { render, staticRenderFns }