<template>
	<data-loader :watch="dataLoaderWatch" :callback="()=>getData()">
		<template #default="{loading}">
			<div>
				<div v-if="loading" class="pa-2 text-center">
					<v-progress-circular indeterminate />
				</div>
				<v-simple-table v-else dense>
					<thead>
					<tr>
						<th>
							Тип,
							Номер,
							Локация
						</th>
						<th>Старо</th>
						<th>Ново</th>
						<th v-for="option in optionsSamootchet" :key="option.value" />
					</tr>
					</thead>
					<tbody>
					<tr v-for="(device, index) in data" :key="index" valign="top">
						<td>
							<v-icon color="green" v-if="device.model && device.model.is_radio==='1'" style="vertical-align: bottom;" title="Радио">mdi-wifi</v-icon>
							{{ device.device_data.device_N }}<br>
							<span class="grey--text">{{ device.device_data.device_type_code }} - {{ device.device.room }}</span>
						</td>
						<td>
							{{ round(device.device_data.value_old, device.decimals) }}
						</td>
						<td>
							{{ round(device.device_data.value_new, device.decimals) }}
						</td>
						<td v-for="option in optionsSamootchet" :key="option.value">
							<decimal-input
								dense persistent-hint clearable
								style="width: 125px"
								v-model="values[ device.device_data.device_N ][ option.value ]"
								:hint="dates[ device.device_data.device_N ][ option.value ]"
								:prepend-icon="option.icon"
								:readonly="readonly(option.value) || locked"
								:disabled="readonly(option.value) || locked"
							/>
						</td>
					</tr>
					</tbody>
				</v-simple-table>
				<v-divider/>
				<v-toolbar flat v-if="!locked">
					<v-spacer/>
					<btn-loading-promise
						:click="()=>saveData()"
					>
						<template #default="{click,loading}">
							<v-btn
								@click="click"
								:loading="loading"
								:disabled="loading"
								color="primary"
								small
							>
								Запиши показанията
							</v-btn>
						</template>
					</btn-loading-promise>
				</v-toolbar>
			</div>
		</template>
	</data-loader>
</template>

<script>
import {ITTIModelBase} from "@/ittijs/ITTIModel";
import DecimalInput from "@/ittijs/Inputs/DecimalInput";
import {SamootchetType, optionsSamootchet} from "@/ittijs/Nomenclatures";
import DataLoader from "@/ittijs/components/DataLoader";
import {formatDate} from "@/ittijs/utils";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";

class Model extends ITTIModelBase {
	constructor() {
		super('SelfReportValues');
	}
	getForImotSession(session, imot, station){
		return this.fetch('getForImotSession', {session, imot, station});
	}
	saveForImotSession(session, imot, station, values){
		return this.fetch('saveForImotSession', {session, imot, station}, values);
	}
}

const model = new Model;

export default {
	components: {
		BtnLoadingPromise,
		DataLoader,
		DecimalInput,
	},
	props: [
		'imot',
		'session',
		'station',
		'locked',
	],
	data: ()=>({
		loading: false,
		data: null,
		values: null,
		dates: null, // same structure as values, but with value dates
		optionsSamootchet,
	}),
	computed: {
		dataLoaderWatch(){
			return {
				imot: this.imot,
				session: this.session,
			}
		},
	},
	watch: {
		imot: {
			immediate: true,
			handler(){
				this.getData();
			},
		},
		session: {
			immediate: true,
			handler(){
				this.getData();
			},
		},
	},
	methods: {
		readonly(type) {
			return type === SamootchetType.WEB;
		},
		round(val, dec){
			if (val) return parseFloat(val).toFixed(dec);
			return '';
		},
		buildValues(){
			this.values = {};
			this.dates = {};
			for (const index in this.data) {
				const deviceN = this.data[index].device_data.device_N;
				this.values[deviceN] = {};
				this.dates[deviceN] = {};
				for (const type of optionsSamootchet) {
					this.values[deviceN][ type.value ] = '';
					this.dates[deviceN][ type.value ] = '';
				}
				for (const value of this.data[index].self_report) {
					this.values[deviceN][ value.samootchet_type ] = value.value;
					this.dates[deviceN][ value.samootchet_type ] = formatDate(value.date);
				}
			}
		},
		async getData() {
			if (!this.session || !this.imot) return;
			this.data = await model.getForImotSession(this.session, this.imot, this.station);
			this.buildValues();
		},
		async saveData() {
			if (!this.session || !this.imot) return;
			this.data = await model.saveForImotSession(this.session, this.imot, this.station, this.values);
			this.buildValues();
		},
	},
}
</script>