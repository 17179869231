<template>
	<div style="display: contents;">
		<list
			ref="list"
			v-if="imodel.loaded"
			:imodel="imodel"
			headers="created_time,created_by,status,completed_by,scheduled_datetime,montaji_processed_by,otcheti_processed_by"
			:params="params"
			hide-select
			hide-export
			:hide-delete="!authorization.hasRole(RolesEnum.JOB_REQUESTS_DELETE)"
			@edit="onEdit($event.id)"
		>
			<template #top-actions>
				<v-btn small color="primary" class="my-1" @click="onAdd">
					<v-icon left>mdi-plus</v-icon>
					Нова заявка
				</v-btn>
			</template>
		</list>
		<edit-popup ref="editPopup"
			title="Заявка"
			:imodel="imodel"
			:id="editId"
			:constrains="{station_id:station,imot_id:imot}"
			:dialog-max-width="1500"
			:left-col-width="200"
			:right-col-width="500"
			:fields="editFields"
			:disabled-fields="disabledEditFields"
			:edit-button-options="{
				showDelete: authorization.hasRole(RolesEnum.JOB_REQUESTS_DELETE),
			}"
			hidden-fields="schedule_id,scheduled_time_start"
			:data-append="{
				services: servicesDataFiltered,
				schedule_id: editScheduleId,
				scheduled_time_start: editScheduleTime,
			}"
			@loaded="onEditLoaded"
			@reload="onReload"
		>
			<template #edit.before-fields="{row}">
				<data-loader
					v-if="selectedUtility && row['imot_id']"
					:watch="{utility: selectedUtility, imot: row['imot_id']}"
					:callback="() => imodel.getImotInfo(selectedUtility, row['imot_id'])"
					:data.sync="imotInfo"
				>
					<template #default>
						<v-row v-if="imotInfo" no-gutters justify="center">
							<v-alert type="info" text dense>
								Имот {{imotInfo.imot_N}}, {{imotInfo.address}}
							</v-alert>
						</v-row>
					</template>
				</data-loader>
			</template>
			<template #edit.after-row.scheduled_duration_minutes="{row,loading,leftColStyle,rightColStyle}">
				<v-row no-gutters justify="center" v-if="!loading">
					<v-col :style="leftColStyle" class="py-1">
						Час по график
					</v-col>
					<v-col :style="rightColStyle" class="pa-1">
						<schedule-details
							:schedule-id="editScheduleId"
							:time-start="editScheduleTime?.substr(0, 5)"
							:duration="parseInt(row['scheduled_duration_minutes'])"
							class="mb-2"
						/>
						<schedule-select
							:schedule-id.sync="editScheduleId"
							:time-start.sync="editScheduleTime"
							:duration="parseInt(row['scheduled_duration_minutes'])"
							@change="onScheduleChange(row)"
							:record-id="editId"
							:imot-id="imot"
							:dialog-title="imotInfo ? `Избор от график: Имот ${imotInfo.imot_N}, ${imotInfo.address}` : null"
						/>
					</v-col>
				</v-row>
			</template>
			<template #edit.after-fields="{row}">
				<v-divider class="mt-2" />
				<JobItemsList
					:job-id="editId==='new' ? null : editId"
					:imot="imot"
					v-model="servicesData"
					:readonly="row['status']=='completed'"
				/>
			</template>
		</edit-popup>
	</div>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */
import List from "@/ittijs/List.vue";
import Model from "@/views/montaji/job_requests/Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import EditPopup from "@/ittijs/components/EditPopup.vue";
import JobItemsList from "@/views/frontdesk/JobItemsList.vue";
import ScheduleSelect from "@/views/montaji/job_requests/ScheduleSelect.vue";
import ScheduleDetails from "@/views/montaji/job_requests/ScheduleDetails.vue";
import {RolesEnum} from "@/ittijs/Authorization";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";

const imodel = ITTIModelProvider.getModel(Model);

export default {
	components: {DataLoader, ScheduleDetails, ScheduleSelect, JobItemsList, EditPopup, List},
	mixins: [
		SelectedUtilityMixin,
	],
	inject: ['authorization'],
	props: [
		'station',
		'imot',
	],
	data(){
		return {
			imodel,
			editId: null,
			servicesData: {},
			editScheduleId: null,
			editScheduleTime: null,
			imotInfo: null,
		}
	},
	computed: {
		RolesEnum() {
			return RolesEnum
		},
		params(){
			return {
				station: this.station,
				imot: this.imot,
			}
		},
		editFields(){
			if (this.editId === 'new') {
				return [
					'status',
					'scheduled_duration_minutes',
					'notes_operator',
				];
			}
			else {
				return [
					'created_time',
					'created_by',
					'status',
					'scheduled_duration_minutes',
					'payment_type',
					'notes_operator',
					'time_started',
					'time_completed',
					'completed_by',
					'notes_montajnik',
				];
			}
		},
		disabledEditFields(){
			return [
				'created_time',
				'created_by',
				'time_started',
				'time_completed',
				'completed_by',
				'notes_montajnik',
			];
		},
		servicesDataFiltered(){
			return {
				values_device : this.servicesData && this.servicesData.values_device && this.servicesData.values_device.map(item => {
					item = {...item};
					delete item['__device'];
					return item;
				}),
				values_imot   : this.servicesData && this.servicesData.values_imot,
			}
		},
	},
	methods: {
		onAdd(){
			this.editId = 'new';
			this.editScheduleId = null;
			this.editScheduleTime = null;
			this.$refs.editPopup.open();
		},
		onEdit(id){
			this.editId = id;
			this.editScheduleId = null;
			this.editScheduleTime = null;
			this.$refs.editPopup.open();
		},
		onEditLoaded(event){
			this.editScheduleId = event.row.schedule_id;
			this.editScheduleTime = event.row.scheduled_time_start;
		},
		onReload(){
			this.$refs.list.refresh();
		},
		onScheduleChange(row){
			if (this.editScheduleId && this.editScheduleTime) {
				if (row['status']=='' || row['status']=='requested') {
					row['status'] = 'scheduled';
				}
			}
			else {
				if (row['status']=='scheduled') {
					row['status'] = 'requested';
				}
			}

		},
	},
}
</script>