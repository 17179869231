<template>
	<v-dialog width="400" v-model="open">
		<template v-slot:activator="{ on, attrs }">
			<v-btn right icon small v-bind="attrs" v-on="on" class="ml-2">
				<v-icon dense color="primary">mdi-pencil</v-icon>
			</v-btn>
		</template>
		<template v-slot:default="">
			<v-card>
				<edit-column
					max-content-height="calc(90vh - 120px)"
					:left-col-width="100"
					v-if="open"
					title="Имот"
					:params="{id:imot}"
					@close="open = false"
					@saveclose="open = false; $emit('reload')"
					:imodel="model"
					:edit-button-options="{showDelete:false}"
				>
				</edit-column>
			</v-card>
		</template>
	</v-dialog>
</template>

<script>
import {ImotFrontDesk} from '../imot/Model';
import EditColumn from "@/ittijs/EditColumn";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

const model = ITTIModelProvider.getModel(ImotFrontDesk);

export default {
	components: {
		EditColumn,
	},
	props: [
		'imot',
	],
	data(){
		return {
			open: false,
			model,
		}
	},
	methods: {
	},
}
</script>