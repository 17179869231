<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
    :items="items"
    hide-no-data
    no-filter
    :loading="loading"
    :search-input.sync="search"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
  </v-autocomplete>
</template>

<script>

import { debounce } from 'lodash';

export default {
  props: {
    debounceTime: {
      type: Number,
      default: 300, // hopefully keep delay + api call below 400
    },
    loading: Boolean,
    items: Array,
    value: {},
  },
  data() {
    return {
      search: '',
      isSearching: false,
      onSearchingDebounce: debounce(this.onSearching, this.debounceTime),
    }
  },
  mounted(){
    if (this.value && !this.selectedItem) {
      this.$emit('load', this.value);
    }
  },
  watch: {
    search(value) {
      if (!value || value == this.value || this.loading) return;
      this.onSearchingDebounce(value);
    },
    value(value){
      if (this.value && !this.selectedItem) {
        this.$emit('load', value);
      }
    },
  },
  computed: {
    selectedItem(){
      return this.items.find(e => e.value == this.value);
    },
  },
  methods: {
    onSearching(value){
      if (value=='') {
        return;
      }
      this.$emit('search', value);
    },
  },
  
}

</script>