<template>
	<div v-if="model">
		<v-divider v-if="loading" />
		<v-progress-linear indeterminate v-if="loading" />
		<v-divider />
		<v-simple-table dense>
			<thead>
				<tr>
					<th>Тип</th>
					<th>Вх. #</th>
					<th>Дата</th>
					<th>Валиден до</th>
					<th>Файл</th>
					<th>Одобрен от</th>
					<th>Приключен от</th>
					<th width="1">
						<v-btn small icon tabindex="-1" @click="insert">
							<v-icon color="primary">mdi-plus</v-icon>
						</v-btn>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="doc in active" :key="doc.id">
					<td><field-value :imodel="model" name="document_type" :value="doc.document_type" /></td>
					<td>D{{doc.id}}</td>
					<td><field-value :imodel="model" name="from_date" :value="doc.from_date" /></td>
					<td><field-value :imodel="model" name="to_date" :value="doc.to_date" /></td>
					<td><mf :value="doc.file" :imodel="model">
						<template #file="{file}">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" :href="file.url" target="_blank" icon small>
										<v-icon>mdi-file-download</v-icon>
									</v-btn>
								</template>
								{{ file.name }}
								<span>({{ formatBytes(file.size) }})</span>
							</v-tooltip>
						</template>
					</mf></td>
					<td><field-value :imodel="model" name="approved_by" :value="doc.approved_by" /></td>
					<td><field-value :imodel="model" name="processed_by" :value="doc.processed_by" /></td>
					<td nowrap>
						<v-btn small icon tabindex="-1" @click="edit(doc)" v-if="fullAccess">
							<v-icon color="primary">mdi-pencil-circle</v-icon>
						</v-btn>
						<v-btn small icon tabindex="-1" @click="remove(doc)" v-if="fullAccess">
							<v-icon color="red">mdi-delete-circle</v-icon>
						</v-btn>
					</td>
				</tr>
				<tr v-if="inactive.length > 0">
					<th colspan="8" class="px-4 py-2" style="height: auto;">
						Архив
					</th>
				</tr>
				<tr v-for="doc in inactive" :key="doc.id">
					<td><field-value :imodel="model" name="document_type" :value="doc.document_type" /></td>
					<td>D{{doc.id}}</td>
					<td><field-value :imodel="model" name="from_date" :value="doc.from_date" /></td>
					<td><field-value :imodel="model" name="to_date" :value="doc.to_date" /></td>
					<td><mf :value="doc.file" :imodel="model">
						<template #file="{file}">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" :href="file.url" target="_blank" icon small>
										<v-icon>mdi-file-download</v-icon>
									</v-btn>
								</template>
								{{ file.name }}
								<span>({{ formatBytes(file.size) }})</span>
							</v-tooltip>
						</template>
					</mf></td>
					<td><field-value :imodel="model" name="approved_by" :value="doc.approved_by" /></td>
					<td><field-value :imodel="model" name="processed_by" :value="doc.processed_by" /></td>
					<td nowrap>
						<v-btn small icon tabindex="-1" @click="edit(doc)" v-if="fullAccess">
							<v-icon color="primary">mdi-pencil-circle</v-icon>
						</v-btn>
						<v-btn small icon tabindex="-1" @click="remove(doc)" v-if="fullAccess">
							<v-icon color="red">mdi-delete-circle</v-icon>
						</v-btn>
					</td>
				</tr>
			</tbody>
		</v-simple-table>

		<v-dialog v-model="editDoc" max-width="600">
			<template v-slot:default="">
				<v-card>
					<edit-column
						max-content-height="calc(90vh - 120px)"
						left-col-width="100"
						v-if="editDoc"
						title="Редакция документ"
						:params="editDocParams"
						@close="editDoc = false"
						@saveclose="editDoc = false; reloadList();"
						ref="EditForm"
						:imodel="model"
						:constrains="{master_id:masterId}"
						:edit-button-options="{showDelete:false}"
						fields="document_type,from_date,to_date,file"
					>
						<template #after-fields="{row}" v-if="!isInsert">
							<v-sheet color="headings3" elevation="1" rounded outlined class="mt-2 pa-2">
								Създаден на <date-time-format :datetime="row.created_time" />
								<user-info :id="parseInt(row.created_by)">
									<template #default="{user}">
										от {{user.user}}
									</template>
								</user-info>
								<user-info :id="parseInt(row.approved_by)" v-if="parseInt(row.approved_by) > 0">
									<template #default="{user}">
										<div>Одобрен от {{user.user}}</div>
									</template>
								</user-info>
								<template v-else-if="fullAccess">
									<div class="text-right">
										<v-btn color="success" small class="mt-2" @click="setApproved">Маркирай като одобрен</v-btn>
									</div>
								</template>
							</v-sheet>
							<v-sheet color="headings3" elevation="1" rounded outlined class="mt-2 pa-2">
								<user-info :id="parseInt(row.processed_by)" v-if="parseInt(row.processed_by) > 0">
									<template #default="{user}">
										<div>УП Приключен от {{user.user}}</div>
									</template>
								</user-info>
								<template v-else-if="fullAccess">
									<div class="text-right">
										<confirm-dialog @confirm="setProcessed">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													v-bind="attrs"
													v-on="on"
													color="success" small
												>Маркирай като приключен (УП)</v-btn>
											</template>
											За уведомителни писма: "Приключен" означава, че предписанията по този документ са изпълнени и
											не е нужно да му се обръща повече внимание.
										</confirm-dialog>
									</div>
								</template>
							</v-sheet>
						</template>
					</edit-column>
				</v-card>
			</template>
		</v-dialog>

		<confirm-dialog ref="deleteConfirm" label-confirm="Изтрий" color-confirm="red darken-1" icon-confirm="mdi-delete">
			Моля потвърдете изтриването на записа
		</confirm-dialog>

	</div>
</template>

<script>
import { isoToDate, formatBytes } from '@/ittijs/utils';
import mf from '@/ittijs/components/MF.vue';
import ConfirmDialog from '@/ittijs/ConfirmDialog.vue';
import {ImotDocumentsModel, StationDocumentsModel} from '../documents/Model';
import UserInfo from "@/ittijs/components/UserInfo";
import DateTimeFormat from "@/ittijs/components/DateTimeFormat";
import FieldValue from "@/ittijs/components/FieldValue";
import {RolesEnum} from "@/ittijs/Authorization";
import EditColumn from "@/ittijs/EditColumn";

const modelImot = new ImotDocumentsModel;
const modelStation = new StationDocumentsModel;

export default {
	components: {
		EditColumn,
		FieldValue,
		DateTimeFormat,
		UserInfo,
		ConfirmDialog,
		mf,
	},
	inject: ['authorization'],
	props: [
		// station and imot are mutually exclusive
		'station',
		'imot',
		'documents',
	],
	data(){
		return {
			editDoc: false,
			editDocParams: {},
			isInsert: false,
			loading: false,
		}
	},
	computed: {
		masterId(){
			return this.imot || this.station || null;
		},
		model(){
			return this.imot && modelImot || this.station && modelStation || null;
		},
		fullAccess(){
			return this.authorization.hasRole(RolesEnum.OTCHETI_FULL)
		},
		active(){
			return this.documents && this.documents.filter(e => this.isDocActive(e)) || [];
		},
		inactive(){
			return this.documents && this.documents.filter(e => !this.isDocActive(e)) || [];
		},
	},
	watch: {
		imot: {
			immediate: true,
			handler(val){
				val && this.reloadList();
			},
		},
		station: {
			immediate: true,
			handler(val){
				val && this.reloadList();
			},
		},
	},
	methods: {
		formatBytes,
		async reloadList(){
			this.loading = true;
			this.$emit('update:documents', await this.model.getDocs(this.masterId));
			this.$emit('reload');
			this.loading = false;
		},
		isDocActive(doc){
			if (!doc.to_date || doc.to_date=='') return true;
			const d = isoToDate(doc.to_date);
			if (!d) return true;
			return d.getTime() > Date.now();
		},
		insert(){
			this.editDocParams = {};
			this.editDoc = true;
			this.isInsert = true;
		},
		edit(doc){
			if (!this.fullAccess) return;
			this.editDocParams = {id:doc.id};
			this.editDoc = true;
			this.isInsert = false;
		},
		remove(doc){
			if (!this.fullAccess) return;
			this.$refs.deleteConfirm.open().then(res=>{
				if (res) {
					this.model.delete({id:doc.id}).then(()=>{
						this.reloadList();
					});
				}
			})
		},
		async setApproved(){
			if (!this.fullAccess) return;
			await this.model.approveDocument(this.editDocParams);
			this.$refs.EditForm.edit();
			this.reloadList();
		},
		async setProcessed(){
			if (!this.fullAccess) return;
			await this.model.setProcessed(this.editDocParams);
			this.$refs.EditForm.edit();
			this.reloadList();
		},
	},
}
</script>