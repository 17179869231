<template>
	<v-dialog max-width="700" v-model="open">
		<template v-slot:activator="{ on, attrs }">
			<v-btn right icon small v-bind="attrs" v-on="on" class="ml-2">
				<v-icon dense color="primary">mdi-pencil</v-icon>
			</v-btn>
		</template>
		<template v-slot:default="">
			<v-card>
				<edit-column
					max-content-height="calc(90vh - 120px)"
					v-if="open"
					title="Абонатна станция"
					:params="{id:station}"
					@close="open = false"
					@saveclose="open = false; $emit('reload')"
					:imodel="model"
					:edit-button-options="{showDelete:false}"
					:fields="[
						'domoupravitel_name',
						'domoupravitel_phone',
						'domoupravitel_email',
						'authorized_persons',
						'notes',
					]"
				>
				</edit-column>
			</v-card>
		</template>
	</v-dialog>
</template>

<script>
import {StationFrontDesk} from "@/views/stations/Model";
import EditColumn from "@/ittijs/EditColumn";

const model = new StationFrontDesk

export default {
	components: {
		EditColumn,
	},
	props: [
		'station',
	],
	data(){
		return {
			open: false,
			model,
		}
	},
}
</script>