<template>
	<data-loader :watch="dataLoaderWatch" :callback="()=>getData()">
		<template #default="{loading,reload}">
			<div>
				<div v-if="loading" class="pa-2 text-center">
					<v-progress-circular indeterminate />
				</div>
				<v-simple-table v-else dense>
					<thead>
					<tr>
						<th>
							Тип,
							Номер,
							Локация
						</th>
						<th></th>
						<th class="text-right">Старо</th>
						<th class="text-right">Ново</th>
						<th class="text-right">Разлика</th>
						<th class="text-left">Тип отчет</th>
						<th class="text-right" v-if="!mesechen">Прогнозно</th>
						<th>Изтича на</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(device, index) in data" :key="index" valign="top">
						<td>
							<v-icon color="green" v-if="device.model && device.model.is_radio==='1'" style="vertical-align: bottom;" title="Радио">mdi-wifi</v-icon>
							{{ device.device_data.device_N }}<br>
							<span class="grey--text">{{ device.device_data.device_type_code }} - {{ device.device.room }}</span>
						</td>
						<td valign="middle"><up-device-alert-wrap
							:up-list="upList || []"
							:device-id="parseInt(device.device.id)"
							@reload="reload"
						/></td>
						<td class="text-right">
							{{ parseFloat(device.device_data.value_old) }}
						</td>
						<td class="text-right">
							{{ parseFloat(device.device_data.value_new) }}
						</td>
						<td class="text-right">
							<val-diff
								:decimals="device.decimals"
								:old="device.device_data.value_old"
								:new="device.device_data.value_new"
							/>
						</td>
						<td>
							<otchet-type disabled
								:otchet="device.device_data.otchet_type"
								:samootchet="device.device_data.samootchet_type"
							/>
						</td>
						<td class="text-right" v-if="!mesechen">
							{{ parseFloat(device.device_data.value_prognozno) }}
						</td>
						<td>
							<date-format :date="device.device.reinstall_date" past-class="red white--text pa-1 rounded my-1" />
						</td>
					</tr>
					</tbody>
				</v-simple-table>
			</div>
		</template>
	</data-loader>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader";
import IModelMixin from "@/ittijs/IModelMixin";
import DateFormat from "@/ittijs/components/DateFormat";
import ValDiff from "@/ittijs/components/ValDiff";
import OtchetType from "@/ittijs/components/OtchetType";
import UpDeviceAlertWrap
	from "@/ittijs/components/UvedomitelniPisma/UpDeviceAlertWrap.vue";

export default {
	components: {
		UpDeviceAlertWrap,
		OtchetType,
		ValDiff,
		DateFormat,
		DataLoader,
	},
	mixins: [
		IModelMixin,
	],
	props: [
		'imot',
		'session',
		'station',
		'mesechen',
		'upList',
	],
	data: ()=>({
		loading: false,
		data: null,
	}),
	computed: {
		dataLoaderWatch(){
			return {
				imot: this.imot,
				session: this.session,
			}
		},
	},
	watch: {
		imot: {
			immediate: true,
			handler(){
				this.getData();
			},
		},
		session: {
			immediate: true,
			handler(){
				this.getData();
			},
		},
	},
	methods: {
		async getData() {
			if (!this.session || !this.imot) return;
			this.data = await this.imodel.getDeviceList(this.session, this.imot, this.station);
		},
	},
}
</script>