<template>
	<div>
		<div v-if="disabled">
			<v-chip v-if="optionOtchet" :color="optionOtchet.color" small label class="ma-1">{{optionOtchet.text}}</v-chip>
			<v-chip v-if="optionSamootchet" :color="optionSamootchet.color" small label class="ma-1">{{optionSamootchet.text}}</v-chip>
		</div>
		<v-btn-toggle v-if="!disabled" v-model="inOtchet" dense class="my-1">
			<v-btn x-small style="min-width: 34px;"
				v-for="opt in optionsOtchet"
				:key="opt.value"
				:value="opt.value"
				tabindex="-1"
				:disabled="disabled"
				:title="opt.text"
				:active-class="opt.color"
			>{{ opt.letter }}</v-btn>
		</v-btn-toggle>
		<br v-if="!disabled" />
		<v-btn-toggle v-if="!disabled" v-model="inSamootchet" dense class="mb-1">
			<v-btn x-small icon style="min-width: 34px;"
				v-for="opt in optionsSamootchet"
				:key="opt.value"
				:value="opt.value"
				tabindex="-1"
				:disabled="disabled"
				:title="opt.text"
				:active-class="opt.color"
			><v-icon small>{{ opt.icon }}</v-icon></v-btn>
		</v-btn-toggle>
	</div>
</template>

<script>
import {optionsOtchet, optionsSamootchet} from "@/ittijs/Nomenclatures";

export default {
	props: {
		otchet: {},
		samootchet: {},
		disabled: Boolean,
	},
	data(){
		return {
			inOtchet: null,
			inSamootchet: null,
			optionsOtchet,
			optionsSamootchet,
		}
	},
	computed: {
		optionOtchet(){
			return this.optionsOtchet.find(o=>o.value==this.otchet);
		},
		optionSamootchet(){
			return this.optionsSamootchet.find(o=>o.value==this.samootchet);
		},
	},
	mounted(){
		this.inOtchet = this.otchet;
		this.inSamootchet = this.samootchet;
	},
	watch: {
		otchet(val){
			this.inOtchet = val;
		},
		samootchet(val){
			this.inSamootchet = val;
		},
		inOtchet(val){
			this.$emit('update:otchet', val);
			if (val != '2') {
				this.inSamootchet = null;
			}
		},
		inSamootchet(val){
			this.$emit('update:samootchet', val);
			if (val) {
				this.inOtchet = '2';
			}
		},
	},
}
</script>