<!--
simple table view for a data object - WITH IMODEL

ако има нужда да се смесват модели, вместо име на колона в масива fields
може да се подаде направо field object от друг модел

data се смесва ръчно
-->

<template>
	<v-card outlined>
		<v-card-title v-if="title">{{ title }}</v-card-title>
		<v-card-text>
			<slot name="before-table" />
			<v-simple-table dense>
				<thead v-if="leftHeading || rightHeading">
				<tr>
					<th>{{ leftHeading }}</th>
					<th>{{ rightHeading }}</th>
				</tr>
				</thead>
				<tbody>
				<slot name="before-rows" />
				<tr v-for="f in fields" :key="key(f)">
					<template v-if="typeof f === 'string'">
						<td><field-label :name="f" /></td>
						<td><field-value style="white-space: pre-wrap" :name="f" :value="data[f]" /></td>
					</template>
					<template v-else-if="typeof f === 'object'">
						<td>{{ f.label || '' }}</td>
						<td><span style="white-space: pre-wrap">{{ f.model.formatView(data[f.name]) }}</span></td>
					</template>
				</tr>
				<slot name="after-rows" />
				</tbody>
			</v-simple-table>
			<slot name="after-table" />
		</v-card-text>
	</v-card>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
import FieldLabel from "@/ittijs/components/FieldLabel";
import FieldValue from "@/ittijs/components/FieldValue";

export default {
	components: {FieldValue, FieldLabel},
	mixins: [IModelMixin],
	props: [
		'data',
		'fields', // array of (string, field obj)
		'title',
		'leftHeading',
		'rightHeading',
	],
	methods: {
		key(f){
			if (typeof f === "string") return f;
			if (typeof f === "object") return f.name;
			return null;
		},
	},
}
</script>