<template>
	<auto-complete-input
		v-bind="$attrs"
		:value="value"
		@input="$emit('input', $event)"
		@search="search"
		@load="load"
		:items="itemsSorted"
		:loading="loading"
		item-text="address"
	>
		<template v-slot:selection="data">
			<span class="grey--text" style="white-space: pre-wrap;">{{data.item.imot_N}}: </span>{{ data.item.address }}
			<v-chip small v-if="data.item.mesechen=='1'" class="mx-1">месечен</v-chip>
			<v-chip small v-else-if="data.item.mesechen=='0'" class="mx-1">годишен</v-chip>
			<v-chip small v-else-if="data.item.active=='0'" class="mx-1" color="error lighten-2">неакт.</v-chip>
		</template>
		<template v-slot:item="data">
			<span class="grey--text" style="white-space: pre-wrap;">{{data.item.imot_N}}: </span>{{ data.item.address }}
			<v-chip small v-if="data.item.mesechen=='1'" class="mx-1">месечен</v-chip>
			<v-chip small v-else-if="data.item.mesechen=='0'" class="mx-1">годишен</v-chip>
			<v-chip small v-else-if="data.item.active=='0'" class="mx-1" color="error lighten-2">неакт.</v-chip>
		</template>
	</auto-complete-input>
</template>

<script>
import AutoCompleteInput from '@/ittijs/Inputs/AutoCompleteInput.vue'
import SelectedUtilityMixin from '@/ittijs/SelectedUtilityMixin';
import { itemsForSelect } from "@/ittijs/utils";
import Model from "./Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

const model = ITTIModelProvider.getModel(Model);

export default {

	mixins: [
		SelectedUtilityMixin,
	],

	components: { AutoCompleteInput },

	props: [
		'value',
		'session',
	],

	data(){
		return {
			items: [],
			loading: false,
		}
	},

	computed: {
		selectedItem(){
			return this.items.find(e => e.value == this.value);
		},
		itemsSorted(){
			return [...this.items].sort((a,b) => parseInt(a.imot_N) - parseInt(b.imot_N));
		},
	},

	watch: {
		// selectedUtility(val){
		//   if (val && this.value && !this.selectedItem) {
		//     this.search(this.value);
		//   }
		//   if (val && this.selectedItem && this.selectedItem.utility_code != val) {
		//     this.items = [];
		//     this.$emit('input', null);
		//   }
		//   if (!val) {
		//     this.items = [];
		//     this.$emit('input', null);
		//   }
		// },
		selectedItem(val){
			this.selectedImot = val ? val.value : null;
		},
	},

	mounted(){
		if (this.selectedImot) {
			this.$emit('input', this.selectedImot);
		}
	},

	methods: {
		search(term){
			if (!this.selectedUtility) return;
			this.loading = true;
			model.getSelectOptions({
				'utility_code' : this.selectedUtility,
				'search_term'  : term,
				'session'      : this.session,
			})
				.then(res=>{
					this.items = itemsForSelect(res);
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => {
					this.loading = false;
				});
		},
		load(val){
			if (!this.selectedUtility) return;
			this.loading = true;
			model.getSelectOptions({
				'utility_code' : this.selectedUtility,
				'id'           : val,
				'session'      : this.session,
			})
				.then(res=>{
					this.items = itemsForSelect(res);
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

}
</script>