<template>
	<v-chip v-if="refNum" :color="color" label small>
		<v-icon left>{{icon}}</v-icon>
		{{refNum}}
	</v-chip>
</template>
<script>
import {TicketRefNumType} from "@/ittijs/Nomenclatures";

export default {
	props: {
		refNum: [String,Number],
		type: [String,Number],
	},
	computed: {
		isOut(){
			return parseInt(this.type) === TicketRefNumType.OUT;
		},
		icon(){
			return this.isOut ? 'mdi-tag-arrow-left' : 'mdi-tag-arrow-right';
		},
		color(){
			return this.isOut ? 'cyan lighten-4' : 'amber lighten-3';
		},
	},
}
</script>