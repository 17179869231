<template>
	<v-container fluid>
		<please-select-utility>
			<v-row dense>
				<v-col>
					<v-toolbar class="headings" dense rounded>
						<div>Сесия:</div>
						<div style="max-width: 500px;" @click.stop>
							<session-select
								v-model="sessionId"
								:utility="selectedUtility"
								:session.sync="sessionData"
								clearable solo hide-details dense flat
								class="ml-4"
								nav
							/>
						</div>

						<v-divider vertical class="mx-2" />

						<div>Аб. Станция:</div>
						<div style="max-width: 550px;" @click.stop>
							<station-select-all
								v-model="stationId"
								:session="sessionId"
								clearable solo hide-details dense flat
								class="flex-grow-1 ml-4"
							/>
						</div>
						<v-spacer v-if="navLink"/>
						<v-btn v-if="navLink" target="_blank" :href="navLink" class="ml-2">
							<v-icon left>mdi-map-marker-radius</v-icon>
							Карта
						</v-btn>
					</v-toolbar>
				</v-col>
			</v-row>

			<v-row dense v-if="loading">
				<v-col>
					<v-progress-linear indeterminate rounded />
				</v-col>
			</v-row>

			<v-row dense v-if="lastStationIzr">
				<v-col>
					<v-alert type="info" outlined class="ma-0">
						Актуалната изравнителна сесия за тази станция е:<br>
						<router-link :to="{
							name: 'frontdesk',
							params: {
								utility: this.utility,
								session: lastStationIzr.id,
								station: this.station,
								imot: null,
							},
						}">
							<v-btn class="mt-2" link>
								<session-item-description :item="lastStationIzr" />
							</v-btn>
						</router-link>
					</v-alert>
				</v-col>
			</v-row>
			<v-row dense v-else-if="deactivatedSessionData">
				<v-col>
					<v-alert type="warning" icon="mdi-alert" dense class="ma-0">
						Последната редовна сесия за тази станция е от <year-month
							:year="parseInt(deactivatedSessionData.year)"
							:month="parseInt(deactivatedSessionData.month)"
						/>
					</v-alert>
				</v-col>
			</v-row>

			<v-row dense v-if="imotList && imotList.length">
				<v-col cols="3">
					<imot-list
						v-model="imotId"
						:items="imotList"
					/>
					<div class="mt-3">
						<v-btn
							small
							:href="imodel.getRequestURL('getStationSpravkaXLS', {session, station})"
							target="_blank"
						>Справка</v-btn>
					</div>
				</v-col>
				<v-col cols="9" v-if="selectedImot && loadedImotData">
					<v-card outlined min-height="100%">
						<v-tabs v-model="tabs">
							<v-tab>Справка</v-tab>
							<v-tab v-if="isIzravnitelna">Обща изр.</v-tab>
							<v-tab>Данни</v-tab>
							<v-tab>Бележки</v-tab>
							<v-tab>Документи</v-tab>
							<v-tab>УП</v-tab>
							<v-tab>Уреди</v-tab>
							<v-tab v-if="isMesechen">Показания/Самоотчет</v-tab>
							<v-tab v-if="showJobRequests">Заявки</v-tab>
							<v-tab v-if="showTickets">Деловодство</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tabs">

							<!-- СПРАВКА -->
							<v-tab-item>
								<v-container>
									<v-toolbar dense rounded outlined flat class="mb-2" v-if="!isIzravnitelna">
										<download-btn
											label="Свали сметките за всички имоти"
											text
											:rest-service="()=>imodel.downloadAllPDFReports(session, station)"
										/>

										<v-spacer/>

										<!-- за неизравнителните показваме on-the-fly репорт -->
										<v-btn color="secondary" class="ml-2"
											:href="imodel.getRequestURL('getReportPrint', {session, imot, station})"
											target="_blank"
										>
											<v-icon left>mdi-printer</v-icon>
											Принт
										</v-btn>
										<v-btn color="secondary" class="ml-2"
											:href="imodel.getRequestURL('getReportPDF', {session, imot, station})"
											target="_blank"
										>
											<v-icon left>mdi-file-pdf-outline</v-icon>
											PDF
										</v-btn>
									</v-toolbar>
									<v-sheet rounded outlined min-height="100%" class="pa-2" v-if="isIzravnitelna">

										<data-loader
											v-if="!loadedImotData.listImotN.length"
											:watch="watchImot"
											:callback="() => imodel.getSmetkaPDF(session, imot, station)"
											@error="throwMe"
										>
											<template #default="{data}">
												<object
													v-if="data"
													:data="data"
													type="application/pdf"
													style="width: 100%; height: 600px"
												></object>
											</template>
										</data-loader>

										<template v-else>
											<v-tabs v-model="tabsListImotN">
												<v-tab v-for="(item, index) in loadedImotData.listImotN" :key="index">
													{{ item.imot_N }} ({{sessionPeriod(item.start, item.end)}})
												</v-tab>
											</v-tabs>
											<v-tabs-items v-model="tabsListImotN">
												<v-tab-item v-for="(item, index) in loadedImotData.listImotN" :key="index">
													<data-loader
														:callback="() => imodel.getSmetkaPDF(session, imot, station, item.imot_N)"
														@error="throwMe"
													>
														<template #default="{data}">
															<object
																v-if="data"
																:data="data"
																type="application/pdf"
																style="width: 100%; height: 600px"
															></object>
														</template>
													</data-loader>
												</v-tab-item>
											</v-tabs-items>
										</template>

									</v-sheet>
									<v-sheet rounded outlined min-height="100%" class="pa-2" v-if="!isIzravnitelna">
										<data-loader :watch="watchImot" :callback="() => imodel.fetch('getReport', {session, imot, station})" @error="throwMe">
											<template #default="{data}">
												<div v-html="data" />
											</template>
										</data-loader>
									</v-sheet>
									<v-banner v-if="othcetDates.length" rounded outlined class="mt-2">
										<div v-for="(otchateDate, index) in othcetDates" :key="index">
											{{ index === 0 ? 'Първа' : 'Втора' }} отчетна дата:
											<a :href="otchateDate.url" :target="otchateDate.url ? '_blank' : null">{{ otchateDate.date }} {{ otchateDate.hour }}</a>
										</div>
									</v-banner>
								</v-container>
							</v-tab-item>

							<!-- ОБЩА ИЗРАВНИТЕЛНА -->
							<data-loader v-if="isIzravnitelna" :watch="watchStation" :callback="() => imodel.getObshtaIzrPDF(session, station)" @error="throwMe">
								<template #default="{data}">
									<v-tab-item>
										<v-container v-if="data">
											<v-sheet rounded outlined min-height="100%" class="pa-2">
												<object
													:data="data"
													type="application/pdf"
													style="width: 100%; height: 600px"
												></object>
											</v-sheet>
										</v-container>
									</v-tab-item>
								</template>
							</data-loader>

							<!-- ДАННИ -->
							<v-tab-item>
								<v-container>
									<v-row dense>
										<v-col cols="6">
											<table-data-view-model
												title="Имот"
												:imodel="imotModel"
												:data="selectedImot.imot"
												:fields="
												Object.keys(imotModel.fields)
												.filter(f => [ // hide those
													'id',
													'utility_code',
													'station_id',
													'keywords',
												].indexOf(f) === -1)
											"
											>
												<template #before-table>
													<div class="text-right">
														<imot-edit-popup
															:imot="selectedImot.imot.id"
															@reload="getImotList"
														/>
													</div>
												</template>
											</table-data-view-model>
										</v-col>
										<v-col cols="6">
											<table-data-view-model
												title="Станция"
												:imodel="stationModel"
												:data="selectedStation.station"
												:fields="[
												'domoupravitel_name',
												'domoupravitel_phone',
												'domoupravitel_email',
												'authorized_persons',
												'notes',
											]"
											>
												<template #before-table>
													<div class="text-right">
														<station-edit-popup
															:session="sessionId"
															:station="stationId"
															@reload="getImotList"
														/>
													</div>
												</template>
											</table-data-view-model>
											<device-in-station v-if="deviceCounts" :deviceCounts="deviceCounts"/>
											<table-data-view-model
												title="За избраната сесия"
												class="mt-2"
												:imodel="imotDataModel"
												:data="selectedImot.data"
												:fields="[
												'broi_lica',
												'hot_water_na_brl',
												'prognozen_dial_bgv',
												'prognozen_dial_heat',
												'prognozen_dial_sgri',
												'station_zone',
												'e_hot_water',
												'e_sgri',
												'e_heat',
											]"
											/>
											<v-card outlined class="mt-2" v-if="loadedImotData && loadedImotData.history && loadedImotData.history.length > 1">
												<v-card-title>Промяна на собственост</v-card-title>
												<v-card-text>
													<v-list three-line>
														<template v-for="(item, index) in loadedImotData.history">
															<v-divider :key="index+10000" />
															<v-subheader :key="index+20000">
																<year-month
																	:year="parseInt(item.year)"
																	:month="parseInt(item.month)"
																/>
															</v-subheader>
															<v-list-item :key="index+30000">
																<v-list-item-content>
																	<v-list-item-title>{{item.imot_N}}: {{item.client_name}}</v-list-item-title>
																	<v-list-item-subtitle>
																		<v-chip label v-if="item.client_phone!=''" class="mr-2">
																			<v-icon left small>mdi-phone-classic</v-icon>
																			{{item.client_phone}}
																		</v-chip>
																		<v-chip label v-if="item.client_phone2!=''" class="mr-2">
																			<v-icon left small>mdi-phone-classic</v-icon>
																			{{item.client_phone2}}
																		</v-chip>
																		<v-chip label v-if="item.client_email!=''" class="mr-2">
																			<v-icon left small>mdi-at</v-icon>
																			{{item.client_email}}
																		</v-chip>
																	</v-list-item-subtitle>
																</v-list-item-content>
															</v-list-item>
														</template>
													</v-list>
												</v-card-text>
											</v-card>
										</v-col>
									</v-row>
								</v-container>
							</v-tab-item>

							<!-- БЕЛЕЖКИ -->
							<v-tab-item>
								<v-container>
									<v-row dense>
										<v-col cols="6">
											<v-card outlined>
												<v-card-title>Имот</v-card-title>
												<v-card-text>
													<imot-data-notes :imot="selectedImot.imot.id" />
												</v-card-text>
											</v-card>
										</v-col>
										<v-col cols="6">
											<v-card outlined>
												<v-card-title>Станция</v-card-title>
												<v-card-text>
													<station-notes :station="stationId" />
												</v-card-text>
											</v-card>
										</v-col>
									</v-row>
								</v-container>
							</v-tab-item>

							<!-- ДОКУМЕНТИ -->
							<v-tab-item>
								<v-container>
									<v-row dense>
										<v-col>
											<v-card outlined>
												<v-card-title>Имот</v-card-title>
												<v-card-text>
													<document-list
														:imot="selectedImot.imot.id"
														:documents.sync="documents"
													/>
												</v-card-text>
											</v-card>
										</v-col>
									</v-row>
									<v-row dense>
										<v-col>
											<v-card outlined>
												<v-card-title>Станция</v-card-title>
												<v-card-text>
													<document-list
														:station="stationId"
														:documents.sync="stationDocuments"
													/>
												</v-card-text>
											</v-card>
										</v-col>
									</v-row>
								</v-container>
							</v-tab-item>

							<!-- УП (уведомителни писма) -->
							<v-tab-item>
								<v-container>
									<UPList :items="loadedImotData.up_list" />
								</v-container>
							</v-tab-item>

							<!-- УРЕДИ -->
							<v-tab-item>
								<v-container>
									<front-desk-device-list
										:imot="imot"
										:session="session"
										:station="station"
										:mesechen="isMesechen"
										:up-list="loadedImotData.up_list"
									/>
									<v-divider class="mb-2"/>
									<CreateUP
										:imot="imot"
										@reload="getImotData"
									/>
								</v-container>
							</v-tab-item>

							<!-- ПОКАЗАНИЯ -->
							<v-tab-item v-if="isMesechen">
								<v-container>
									<self-report-edit
										:imot="imot"
										:session="session"
										:station="station"
										:locked="isLocked"
									/>
								</v-container>
							</v-tab-item>

							<!-- ЗАЯВКИ -->
							<v-tab-item v-if="showJobRequests">
								<v-container>
									<job-requests
										:imot="imot"
										:station="station"
									/>
								</v-container>
							</v-tab-item>

							<!-- ДЕЛОВОДСТВО -->
							<v-tab-item v-if="showTickets">
								<v-container>
									<v-row dense>
										<v-col>
											<v-card outlined>
												<v-card-title>Имот</v-card-title>
												<v-card-text>
													<tickets
														:imot="imot"
													/>
												</v-card-text>
											</v-card>
										</v-col>
									</v-row>
									<v-row dense>
										<v-col>
											<v-card outlined>
												<v-card-title>Станция</v-card-title>
												<v-card-text>
													<tickets
														:station="station"
													/>
												</v-card-text>
											</v-card>
										</v-col>
									</v-row>
								</v-container>
							</v-tab-item>

						</v-tabs-items>
					</v-card>
				</v-col>
			</v-row>
		</please-select-utility>
	</v-container>
</template>

<script>
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import SessionSelect from "@/views/sessions/SessionSelect";
import StationSelectAll from "@/views/stations/StationSelectAll";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import Model from "./Model";
import ImotModel from "../imot/Model";
import ImotDataModel from "../imot_data/Model";
import StationModel from "../stations/Model";
import ImotList from "@/views/clients_screen/ImotList";
import ImotDataNotes from "@/views/clients_screen/ImotDataNotes";
import DocumentList from "@/views/clients_screen/DocumentList";
import TableDataViewModel from "@/ittijs/components/TableDataViewModel";
import DataLoader from "@/ittijs/components/DataLoader";
import ImotEditPopup from "@/views/frontdesk/ImotEditPopup";
import YearMonth from "@/ittijs/components/YearMonth";
import ExpansionPanelShort from "@/ittijs/ExpansionPanelShort";
import StationEditPopup from "./StationEditPopup";
import StationNotes from "@/views/clients_screen/StationNotes";
import SelfReportEdit from "@/views/frontdesk/SelfReportEdit";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import FrontDeskDeviceList from "@/views/frontdesk/FrontDeskDeviceList";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
import DownloadBtn from "@/ittijs/components/DownloadBtn";
import SessionItemDescription from "@/views/sessions/SessionItemDescription";
import UPList from "@/ittijs/components/UvedomitelniPisma/UPList.vue";
import CreateUP from "@/ittijs/components/UvedomitelniPisma/CreateUP.vue";
import JobRequests from "@/views/frontdesk/JobRequests.vue";
import {debounceAsync, stationMapUrl} from "@/ittijs/utils";
import {RolesEnum} from "@/ittijs/Authorization";
import Tickets from "@/views/frontdesk/Tickets.vue";
import DeviceInStation from "@/views/devices/DeviceInStation.vue";

const imodel = ITTIModelProvider.getModel(Model);
const imotModel = ITTIModelProvider.getModel(ImotModel);
const imotDataModel = ITTIModelProvider.getModel(ImotDataModel);
const stationModel = ITTIModelProvider.getModel(StationModel);

export default {
	components: {
		DeviceInStation,
		Tickets,
		CreateUP,
		JobRequests,
		UPList,
		SessionItemDescription,
		DownloadBtn,
		FrontDeskDeviceList,
		SelfReportEdit,
		StationNotes,
		StationEditPopup,
		YearMonth,
		ImotEditPopup,
		DataLoader,
		TableDataViewModel,
		DocumentList,
		ImotDataNotes,
		ImotList,
		StationSelectAll,
		SessionSelect,
		PleaseSelectUtility,
	},

	provide: {
		imodel,
	},
	inject: ['authorization'],

	mixins: [
		ExpansionPanelShort,
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
			session: 'sessionId',
			station: 'stationId',
			imot: 'imotId',
		}),
		ThrowMeMixin,
	],

	props: {
		utility: {},
		session: {},
		station: {},
		imot: {},
	},

	data(){
		return {
			sessionId: null,
			sessionData: null,
			stationId: null,
			imotId: null,
			sessionPrev: null,
			sessionNext: null,
			imotList: [],
			othcetDates: [],
			selectedStation: null,
			deactivatedSessionData: null,
			lastStationIzr: null,
			deviceCounts: null,
			getImotListDebounce: debounceAsync(this.getImotList, 100),
			getStationDebounce: debounceAsync(this.getStation, 100),
			loading: false,
			imotModel,
			imotDataModel,
			stationModel,
			documents: [],
			stationDocuments: [],
			imodel,
			tabs: null,
			tabsListImotN: null,

			loadedImotData: null,
			loadedStationData: null,
			loadingImot: false,
		}
	},

	computed: {
		watchImot(){
			return {
				session: this.session,
				imot: this.imot,
				station: this.station,
			}
		},
		watchStation(){
			return {
				session: this.session,
				station: this.station,
			}
		},
		selectedImot(){
			return this.imotList.find(i=>i.data.imot_id==this.imotId) || null;
		},
		isLocked(){
			return this.sessionData && this.sessionData.locked==='1';
		},
		isIzravnitelna(){
			return this.sessionData && this.sessionData.izr_sess!='0';
		},
		isMesechen(){
			return this.selectedStation && this.selectedStation.data.mesechen==='1';
		},
		showJobRequests(){
			return this.authorization.hasRole(RolesEnum.JOB_REQUESTS);
		},
		showTickets(){
			return this.authorization.hasAnyRole([
				RolesEnum.TICKET_ADMIN,
				RolesEnum.TICKET_NORMAL,
				RolesEnum.TICKET_READONLY,
			]);
		},
		navLink() {
			return stationMapUrl(this.loadedStationData);
		},
	},

	watch: {
		sessionId(val){
			if (!val) {
				this.stationId = null;
				this.imotId = null;
			}
			this.getImotListDebounce();
		},
		stationId(val){
			if (!val) {
				this.imotId = null;
			}
			this.getImotListDebounce();
			this.getStationDebounce();
		},
		imotId(val){
			val && this.getImotData();
		},
	},

	methods: {
		async getImotList() {
			if (this.loading) return;
			this.loading = true;
			this.imotList = [];
			this.othcetDates = [];
			this.selectedStation = null;
			this.deactivatedSessionData = null;
			this.lastStationIzr = null;
			if (this.sessionId > 0 && this.stationId > 0) {
				const res = await imodel.getImotList(this.sessionId, this.stationId);
				this.othcetDates = res.otchet_dates;
				this.imotList = res.imoti ?? [];
				this.selectedStation = {
					station: res.station,
					data: res.station_data,
				};
				this.deactivatedSessionData = res.deactivated_session;
				this.lastStationIzr = res.lastStationIzr;
				this.deviceCounts = res.device_counts;
				// unset imotId if not in current list
				if (this.imotId!='') {
					if (!(res.imoti ?? []).find(el=>el.data.imot_id == this.imotId)) {
						this.imotId = null;
					}
				}
			}
			this.loading = false;
			this.$nextTick(this.getImotData);
		},
		async getImotData() {
			if (this.loadingImot) return;
			if (!(this.sessionId > 0 && this.imotId > 0)) return;
			this.loadingImot = true;
			this.loadedImotData = await imodel.getImotData(this.sessionId, this.imotId, this.stationId);
			this.loadingImot = false;
		},
		async getStation() {
			if (this.loadingStation) return;
			if (!this.stationId) return;
			this.loadingStation = true;
			this.loadedStationData = await stationModel.getRowById(this.stationId);
			this.loadingStation = false;
		},
		sessionPeriod(start, end) {
			if (start == end) return start;
			return `${start} - ${end}`;
		},
	},

}
</script>