<!--
Ако искаш в едит форма да ползваш къстъм контрола, която не се регистрира в inputGroups,
можеш да я обвиеш в този компонент
-->
<script>
export default {

	props: {
		name: String,
	},

	inject: {
		inputGroups: {
			default: null,
		},
	},

	created () {
		this.inputGroups && this.inputGroups.regInput('edit', this);
	},

	beforeDestroy () {
		this.inputGroups && this.inputGroups.unregInput('edit', this);
	},

	render() {
		return this.$slots.default ? this.$slots.default : null;
	},

}
</script>