<template>
	<div class="mf-container">
		<slot name="file" v-for="f in files" v-bind:file="f">
			<a :key="f.id" :href="f.url" target="_blank">
				{{ f.name }}
				<span v-if="showSize">({{ formatBytes(f.size) }})</span>
			</a>
		</slot>
	</div>
</template>

<script>
import IModelMixin from '../IModelMixin';
import {formatBytes,fileToMaterialIcon} from '@/ittijs/utils';

export default {
	mixins: [
		IModelMixin,
	],
	props: {
		value: String,
		// can be provided an object with file infos to use instead of asking the model
		fileinfo: {
			type: Object,
			default: ()=>({}),
		},
		showSize: Boolean,
		showIcon: Boolean,
	},
	data() {
		return {
			files: [],
			getInfoQueue: [], // array of file ids
			loading: false,
		};
	},
	watch: {
		value: {
			handler(to){
				if (to == this.files.map(e=>e.id).join(',')) return; // files are the same, do not rebuild
				const ids = to && this.explodeIDs(to) || [];
				this.files = ids.map(id=>{
					if (this.fileinfo[id]) {
						return {id, ...this.fileinfo[id]};
					}
					else {
						this.getInfoQueue.push(id);
						return {id};
					}
				});
				this.processInfoQueue();
			},
			immediate: true,
		},
	},
	methods: {
		formatBytes,
		fileToMaterialIcon,
		explodeIDs(idList){
			return idList.split(',').filter(s => s!=='');
		},
		processInfoQueue(){
			if (this.getInfoQueue.length === 0) return;
			if (this.loading) return;
			this.loading = true;
			this.imodel.mfInfo(this.getInfoQueue).then(info=>{
				for (const id in info) {
					const finfo = info[id];
					for (const i in this.files) {
						if (id == this.files[i].id && !this.files[i].name) {
							// preserve reactivity, don't assign to this.files[i]
							this.$set(this.files, i, finfo);
						}
					}
				}
				this.loading = false;
			});
		},
	},
}
</script>

<style scoped>
.mf-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
</style>