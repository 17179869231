<template>
	<date-format :date="date" :past-class="pastClass" />
</template>
<script>
import DateFormat from "@/ittijs/components/DateFormat.vue";
import {TicketStatus} from "@/ittijs/Nomenclatures";
export default {
	components: {DateFormat},
	props: {
		date: String,
		status: String,
	},
	computed: {
		pastClass(){
			switch (this.status) {
				case TicketStatus.QUESTION_FOR_CUSTOMER:
				case TicketStatus.RESPONSE_FOR_CUSTOMER:
				case TicketStatus.NOTICE_ACCEPTED:
					return '';
				default:
					return 'red white--text rounded pa-1';
			}
		},
	},
}
</script>