<template>
	<component :is="component" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import NewTicket from "./NewTicket.vue";
import EditTicket from "./EditTicket.vue";

export default {
	computed: {
		component(){
			return parseInt(this.$attrs['id']) > 0 ? EditTicket : NewTicket;
		},
	},
}
</script>