import {ITTIModelBase} from '../../ittijs/ITTIModel';
export default class extends ITTIModelBase {
	constructor() {
		super('FrontDesk');
	}
	getImotList(session, station){
		return this.fetch('getImotList', {session, station});
	}
	getImotData(session, imot, station) {
		return this.fetch('getImotData', {session, imot, station});
	}
	getDeviceList(session, imot, station) {
		return this.fetch('getDeviceList', {session, imot, station});
	}

	/**
	 * imotN е за изравнителни сесии, в които номерът на имота се сменя
	 * по време на периода. В този случай трябва да кажем за кой номер
	 * искаме сметка.
	 * @param session
	 * @param imot
	 * @param station
	 * @param imotN
	 * @returns {Promise<any>}
	 */
	getSmetkaPDF(session, imot, station, imotN = null) {
		return this.fetch('getSmetkaPDF', {session, imot, station, imotN});
	}
	getObshtaIzrPDF(session, station) {
		return this.fetch('getObshtaIzrPDF', {session, station});
	}
	downloadAllPDFReports(session, station) {
		return this.fetch('downloadAllPDFReports', {session, station});
	}
}