<template>
	<edit-column
		style="width: 700px"
		v-bind="$attrs"
		v-on="$listeners"
		:fields="[
			'utility_code',
			'imot_id',
			'station_id',
			'other_parties',
			'additional_parties',
			'is_confidential',
			'doc_description',
			'notes',
			'legal_case_num',
			'deadline',
		]"
		:left-col-width="150"
		save-no-close
		:init="{utility_code:selectedUtility}"
	>
		<template #field.imot_id="{row,disabled}">
			<edit-input-wrapper name="imot_id">
				<imot-select outlined dense clearable hide-details="auto" v-model="row.imot_id" :disabled="disabled" />
			</edit-input-wrapper>
		</template>
		<template #field.station_id="{row,disabled}">
			<edit-input-wrapper name="station_id">
				<station-select outlined dense clearable hide-details="auto" v-model="row.station_id" :disabled="disabled" />
			</edit-input-wrapper>
		</template>
		<template #field.doc_description="{row,disabled}">
			<edit-input-wrapper name="doc_description">
				<v-combobox :items="TicketDocumentTypes" outlined dense clearable hide-details="auto" v-model="row.doc_description" :disabled="disabled" />
			</edit-input-wrapper>
		</template>
		<template #after-fields="{row,leftColStyle,rightColStyle}">
			<v-row no-gutters justify="center">
				<v-col class="pt-3" :style="leftColStyle">Кореспонд. #</v-col>
				<v-col class="pa-1" :style="rightColStyle">
					<edit-input-wrapper name="ref_num_other">
						<v-text-field
							v-model="row.ref_num_other"
							outlined dense hide-details="auto"
						/>
					</edit-input-wrapper>
				</v-col>
			</v-row>
			<v-row no-gutters justify="center">
				<v-col class="pt-3" :style="leftColStyle">Статус</v-col>
				<v-col class="pa-1" :style="rightColStyle">
					<data-loader :callback="()=>imodel.getAvailableStatuses(null)">
						<template #loading><v-progress-linear indeterminate /></template>
						<template #default="{data}">
							<edit-input type="btn-toggle" dense :options="data" name="status" :data="row" :rules="[Validators.required()]" />
						</template>
					</data-loader>
				</v-col>
			</v-row>
			<v-row no-gutters justify="center">
				<v-col class="pt-3" :style="leftColStyle">Файлове</v-col>
				<v-col class="pa-1" :style="rightColStyle">
					<edit-input-wrapper name="files">
						<managed-files
							label="Файлове"
							v-model="row.files"
							warn-filesize-single="2MB"
						/>
					</edit-input-wrapper>
				</v-col>
			</v-row>
		</template>
	</edit-column>
</template>

<script>
import EditColumn from "@/ittijs/EditColumn.vue";
import ManagedFiles from "@/ittijs/Inputs/ManagedFiles.vue";
import StationSelect from "@/views/stations/StationSelect.vue";
import ImotSelect from "@/views/imot/ImotSelect.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import EditInputWrapper from "@/ittijs/Inputs/EditInputWrapper.vue";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import IModelMixin from "@/ittijs/IModelMixin";
import EditInput from "@/ittijs/Inputs/EditInput.vue";
import Validators from "@/ittijs/Validators";
import {TicketDocumentTypes} from "@/ittijs/Nomenclatures";

export default {
	computed  : {
		Validators() {
			return Validators
		},
		TicketDocumentTypes(){
			return TicketDocumentTypes;
		},
	},
	components: {EditInput, DataLoader, EditInputWrapper, ImotSelect, StationSelect, ManagedFiles, EditColumn},
	mixins: [SelectedUtilityMixin,IModelMixin],
}
</script>